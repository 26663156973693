import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import { TitleHeader } from "../components/defaults/styles/elements"
import Business from "../components/defaults/assets/svg/business.svg"
import GeneralFooter from '../components/general_footer';
import { color } from "../components/defaults/styles/theme";
import { Banner } from "../components/defaults/styles/elements";
import { CardGroup } from '../components/main/card_group';
import PartnerGroups from '../components/defaults/filters/partnerGroups';

const seo = {
  frontmatter: {
    title: "Partners",
  },
}

const Partner = ({ data }) => {
  
    return (
      <>
        <Layout seo={seo}>
          <TitleHeader
            title="Platform Partner"
            subtitle="<p>It all started with an idea. In 2016, the four founding partners: Daimler, Plug and Play, ARENA2036 and Universität Stuttgart – recognised the value and importance of working with active startups in an open, cross-collaborative format based in Stuttgart, Germany.</p><p>Today we have grown to 34 partners from across industries and borders. Together we believe in a culture of open doors, disruptive thinking and the exchange of ideas, technologies and contacts. We are driven by the energy and creativity of startups and seek to facilitate growth and innovation within the industry. Since our platform’s inception, we have realized over 390 pilot projects & implementations with over 259 startups.</p>"
            icon={{
              svg: <Business />,
              style: "fill"
            }}
          />
          <Banner
            type="info"
            content="<p>Although the information below has been curated with the utmost attention to every detail, the STARTUP AUTOBAHN team cannot guarantee for its flawlesness.</p><p>If you would like to report mistakes or request changes please reach out to <a href=mailto:marketing-ger@pnptc.com>marketing-ger@pnptc.com</a></p>"
            css={css`
              z-index: 1;
              position: relative;
              a {
                white-space: nowrap;
              }
            `}
          />
          <section
            css={css`
              background: ${color.main_light};
              padding-top: 7rem;
              margin-top: -2.5rem;
            `}
          >
            {PartnerGroups(data.allAirtable.group, "graphQlGroup").map((edges, index) => (
              <CardGroup
                data={edges}
                key={index}
                table={data.allAirtable.distinct}
              />
            ))}
          </section>

          <GeneralFooter theme={color.main_light} />
        </Layout>
      </>
    )
}

export const query = graphql`
  query PartnerQuery {
    allAirtable(filter: {data: {active: {eq: true}}, table: {eq: "partners"}}, sort: { fields: [data___start_date, data___name], order: ASC }) {
      group(field: data___type) {
        edges {
          node {
            data {
              name
              hq_country
              start_date
              type
              url
            }
            table
            recordId
          }
        }
        fieldValue
      }
      distinct(field: table)
    }
  }
`

export default Partner;